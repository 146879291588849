import * as React from 'react'
import Layout from '../../components/layout'
import { Link, graphql } from 'gatsby'
import * as style from './blog.module.css'



const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Blog">
      <ul>
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <Link to={`/blog/${node.slug}`} className="hover:underline hover:text-white transition-all duration-300">
              {node.frontmatter.title}
            </Link>
            <p className="text-textSecondary text-lg">{node.frontmatter.date}</p>
          </article>
        ))
      }
      </ul>
    </Layout>
  )
}

export const query = graphql`
query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          tags
        }
        id
        slug
        timeToRead
      }
    }
  }
`

export default BlogPage